import React from "react";
import NavBar from "./sections/NavBar";
import Splash from "./sections/Splash";
import Services from "./sections/Services";
import TagLine from "./sections/TagLine";
import "./css/App.scss";
import Portfolio from "./sections/Portfolio";
// import PortfolioFlip from './sections/PortfolioFlip';
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Splash />
      <TagLine />
      <Services />
      <Portfolio />
      {/* <PortfolioFlip /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
