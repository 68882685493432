import React from 'react';
import { isMobile } from './Helpers';



class SingleService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: this.props.icon,
            backgroundColor: { backgroundColor: "transparent" },
            height:150
        }
        //bind this
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    
    handleMouseOver() {
        this.setState({
            src: this.props.hoverIcon,
            backgroundColor: { backgroundColor: `${this.props.color}` }
        });
    }
    handleMouseOut() {
        this.setState({
            src: this.props.icon,
            backgroundColor: { backgroundColor: "transparent" }
        });
    }

    //If mobile show icons
    mobileShow() {
        if (isMobile()) {
            this.setState({
                height: 90
            });
            this.handleMouseOver();
        } else {
            this.setState({
                height: 125
            });
            this.handleMouseOut();
        }
    }
    //Add resize browser event listener
    //Bind this to make available in mobileShow method
    componentDidMount() {
        this.mobileShow();
        window.addEventListener("resize", this.mobileShow.bind(this));
    }
    //Remove resize browser  event listener
    componentWillUnmount() {
        window.removeEventListener("resize", this.mobileShow.bind(this));
    }

    render() {
        return (
            <div className="serviceDiv text-center"
                onMouseOver={isMobile() ? () => { return false; } : this.handleMouseOver}
                onMouseOut={isMobile() ? () => { return false; } : this.handleMouseOut}
            >
                <span className="serviceCircle" style={this.state.backgroundColor}>
                    <img src={this.state.src} alt={this.props.alt} height={this.state.height} />
                </span>
                <h3 className="serviceHeading">{this.props.heading}</h3>
                <p>{this.props.paragraph}</p>
            </div>
        )
    }
}


export default SingleService