import React from "react";
import EmailForm from "../components/EmailForm";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../components/SectionTitle";
import styled from "styled-components";

const Section = styled.section`
  scroll-margin-top: calc(70px + 2.5em);
  @media (min-width: 1632px) {
    margin-top: 80px;
  }
`;

const Contact = () => (
  <Section id="contact">
    <Container>
      <Row>
        <SectionTitle title="How Can We Help" />
      </Row>
    </Container>
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <EmailForm URL="https://jfgwwtd1w0.execute-api.us-east-1.amazonaws.com/prod/contact-email" />
        </Col>
      </Row>
    </Container>
  </Section>
);

export default Contact;
