import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    ${props => {
        if (props.primary) {
            return `
            background-color: ${props.theme.colors.primaryButtonBackground}; 
            color: ${props.theme.colors.primaryButtonFont}; 

            `
        } else {
            return `
            color: #fff;
            background-color: #007bff;
            border-color: #007bff;
            `
        }
    }}
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: ${props => props.squared ? "0px" : ".25rem"} ;
        padding:10px;
    &:hover{
        
        ${props => {
        if (props.primary) {
            return `background-color: ${props.theme.colors.primaryButtonBackgroundHover};`
        } else {
            return `
            color: #fff;
            background-color: #0069d9;
            border-color: #0062cc;
            `;
        }
    }}
        
    }
`;

const Button = ({ children, ...props }) => (
    <StyledButton {...props}>{children}</StyledButton>
)

export default Button;