import React from 'react';
import styled from 'styled-components';
import AutoCopyright from '../components/AutoCopyright';

const CopyrightLogo = styled.div`
text-align: center;
  font-size: 12px;
  color: white;
  width: 100%;
  background-color: #373536;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Footer = () => (
  <footer>
    <CopyrightLogo>
      <p>
        <AutoCopyright 
        year={2020}
        text="Imagine More Technologies LLC" />
      </p>
    </CopyrightLogo>
  </footer>
)

export default Footer