import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TagLine = () => (
  <section id="tag-line" className="mt-4">
    <Container>
      <Row>
        <Col>
          <blockquote className="blockquote text-center mb-5">
            <p>
              <strong>Take your idea to the next level!</strong>
            </p>
            <p className="mb-0">
              {/* Imagine your small business running more efficiently and effectivly with stunning results in a short amount of time. We can provide you with the solutions you need at the price you can afford. */}
              Imagine your small business running more efficiently and
              effectively with the solutions you need. We can promptly provide
              you with stunning results at the price you can afford.
            </p>
            {/* <footer className="blockquote-footer">Sam Slosser <cite title="Source Title">IMD</cite></footer> */}
          </blockquote>
        </Col>
      </Row>
    </Container>
  </section>
);

export default TagLine;
