import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

const BSNavbar = () => {
  return (
    <Navbar
      collapseOnSelect
      className="border-bottom"
      fixed="top"
      bg="light"
      expand="lg"
    >
      <Container className="mt-0 mb-0">
        <Navbar.Brand href="#home">Imagine More Technologies</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="md-auto">
            <Nav.Link href="#splash">Home</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default BSNavbar;
