import React from 'react';
import styled from 'styled-components';
const StyledDiv = styled.div`
  margin-bottom:1rem;
  margin-top:1rem;
  position:relative;
`;
const FormGroup = ({ children, ...props }) => (
    <StyledDiv {...props}>{children}</StyledDiv>
)

export default FormGroup