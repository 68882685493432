import React from "react";
import { isMobile } from "../components/Helpers";
import CrossfadeImage from "react-crossfade-image";
import MainSVG from "../images/svg/min/llama.svg";
import MainMobile from "../images/svg/min/llamaMobile.svg";
import MainSVGBW from "../images/svg/min/llamaBW.svg";
import styled from "styled-components";

const Section = styled.section`
  scroll-margin-top: 70px;
`;
class Splash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: { img: MainSVGBW },
      alt: "Llama Black / White",
    };
  }
  handleMouseOver = () => {
    this.setState({
      src: { img: MainSVG },
      alt: "Llama Color",
    });
  };
  handleMouseOut = () => {
    this.setState({
      src: { img: MainSVGBW },
      alt: "Llama Black / White",
    });
  };
  //If mobile show color image
  //IMPROVEMENT can create new function to change to mobile optimize photo
  mobileShow() {
    if (isMobile()) {
      this.handleMouseOver();
    } else {
      this.handleMouseOut();
    }
  }
  //Add event listener
  componentDidMount() {
    this.mobileShow();
    window.addEventListener("resize", this.mobileShow.bind(this));
  }
  //Remove event listener
  componentWillUnmount() {
    window.removeEventListener("resize", this.mobileShow.bind(this));
  }
  render() {
    //if smaller in small mobile show static image
    //else go back to crossfading image
    let splashIMG;
    if (isMobile(576))
      splashIMG = (
        <img
          src={MainMobile}
          style={{ width: "100%", height: "auto" }}
          alt="Main"
        />
      );
    else
      splashIMG = (
        <CrossfadeImage
          style={{ width: "100%", height: "auto" }}
          src={this.state.src.img}
          alt={this.state.alt}
        />
      );

    return (
      <Section
        id="splash"
        onMouseOver={
          isMobile()
            ? () => {
                return false;
              }
            : this.handleMouseOver
        }
        onMouseOut={
          isMobile()
            ? () => {
                return false;
              }
            : this.handleMouseOut
        }
      >
        {splashIMG}
      </Section>
    );
  }
}

export default Splash;
