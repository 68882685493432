import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import SectionTitle from "../components/SectionTitle";
import PortfolioCard from "../components/PortfolioCard";
import UNC from "../images/catering.jpg";
import SSWedding from "../images/SWedding.jpg";
import LostValley from "../images/LostValley.jpg";
// import Webbs from "../images/webbs.jpg";
import Countryside from "../images/countryside.jpg";
import Steph from "../images/steph.jpg";
import Sam from "../images/sam.jpg";

const Section = styled.section`
  scroll-margin-top: calc(70px + 2.5em);
  @media (min-width: 1632px) {
    margin-top: 80px;
  }
`;

const Portfolio = () => (
  <Section id="portfolio">
    <Container>
      <Row>
        <SectionTitle title="Our Past Work" />
      </Row>
    </Container>

    <div className="flex-container">
      <div className="d-flex flex-wrap justify-content-center">
        <PortfolioCard
          link="https://upnorthcatering.com"
          image={UNC}
          title="Up North Catering"
          text="Up North Catering L.L.C. is an established
                    small business in Northern Michigan. Once
                    the site went live business inquiries
                    increased 40%."
        />

        <PortfolioCard
          link="https://happilyeverslosser.imaginemoretechnologies.com/"
          image={SSWedding}
          title="Wonderful Wedding"
          text="This site was customized to show the uniqueness
                    of the couple. You will find a dynamic firework
                    display on the splash page and then information about the wedding."
        />

        <PortfolioCard
          link="https://lostvalleycampground.com"
          image={LostValley}
          title="Lost Valley Campground"
          text="This site was custom built from the ground up.
                    It features mobile first development (optimized
                    for tablets / phones), single page dynamic calendar, and document downloads."
        />

        {/* <PortfolioCard
          link="http://webbwelldrilling.com"
          image={Webbs}
          title="R Webb &amp; Son Well Drilling"
          text="R. Webb &amp; Son Well Drilling is the
                    best locally owned and operated well
                    drilling and water purification company in
                    Michigan."
        /> */}

        <PortfolioCard
          link="https://countrysideappliancellc.com/"
          image={Countryside}
          title="Countryside Appliance"
          text="Countryside Appliance is a wonderful small business
                    located in West Branch, Michigan. They provide
                    excellent service and high quality appliance repair."
        />

        <PortfolioCard
          link="https://stephanienicolewindsor.com"
          image={Steph}
          title="Custom Tailored"
          text="This site was built to to provide an online
                    portfolio. This site contains a full resume,
                    photo gallery for certificates and contact information."
        />

        <PortfolioCard
          link="https://samuelslosser.com"
          image={Sam}
          title="Clean Professional"
          text="Personal marketing and branding are important in the professional world.  A clean professional portfolio site will show potential employers, customers, or clients your skills and experience."
        />
      </div>
    </div>
  </Section>
);

export default Portfolio;
