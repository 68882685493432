import React from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import SingleService from "../components/SingleService";
import SectionTitle from "../components/SectionTitle";
import CloudComputing from "../icons/min/CloudNB.svg";
import CloudBW from "../icons/min/CloudBW.svg";
import Coding from "../icons/min/CodingNB.svg";
import CodingBW from "../icons/min/CodingBW.svg";
import UIUX from "../icons/min/UIUXNB.svg";
import UIUXBW from "../icons/min/UIUXBW.svg";
// import Networking from '../icons/min/NetworkingNB.svg';
// import NetworkingBW from '../icons/min/NetworkingBW.svg';
import Database from "../icons/min/DatabaseNB.svg";
import DatabaseBW from "../icons/min/DatabaseBW.svg";
import Email from "../icons/min/EmailNB.svg";
import EmailBW from "../icons/min/EmailBW.svg";
// import WifiIcon from '../icons/min/WifiNB.svg';
// import WifiBW from '../icons/min/WifiBW.svg';

const Section = styled.section`
  scroll-margin-top: calc(70px + 2.5em);
  @media (min-width: 1632px) {
    margin-top: 80px;
  }
`;
const Services = () => (
  <Section id="services">
    <Container>
      <Row>
        <SectionTitle title="What We Can Do For You" />
      </Row>
    </Container>

    <Container fluid>
      {/* remove flex-container to go full width */}
      <div className="flex-container">
        <div className="d-flex flex-wrap justify-content-center">
          <SingleService
            icon={UIUXBW}
            hoverIcon={UIUX}
            color="pink"
            alt="Web Development"
            heading="Web Development"
            paragraph="We are here to help you grow your digital presence.  We can build informational sites, stores, personal sites, warehousing applications... the limit is your imagination.  Your applications will be highly available at all times and optimized for the web.  We have the knowledge and experience to turn any idea into a fully functioning, high-speed application."
          />
          <SingleService
            icon={CloudBW}
            hoverIcon={CloudComputing}
            color="orange"
            alt="Cloud Computing"
            heading="Cloud Computing"
            paragraph="We have the experience required to modernize your current local applications.  We can migrate your applications to a public or private cloud instance, whichever best suits your business needs.  Let us ensure your applications are ready to handle future growth and security."
          />
          <SingleService
            icon={CodingBW}
            hoverIcon={Coding}
            color="rgb(37, 113, 212)"
            alt="Coding Icon"
            heading="Custom Coding"
            paragraph="Do you need your application to do something extra or have an idea for one that you would like to launch? We specialize in application analysis and design always keeping your business needs in mind. We follow modern and standard practices with all of our applications. We can design, build, and host your dream web application."
          />

          {/* <SingleService
                    icon={NetworkingBW}
                    hoverIcon={Networking}
                    color="green"
                    alt="Networking Icon"
                    heading="Networking"
                    paragraph="Does your business need to support IoT devices, IP Cameras, PCs, Wireless Access Points, and other networking equipment?  We can provide an all-in-one network solution with easy to use instructions.  Preventing downtime and securely providing internet access is critical to your business.  We can design and build the most cost-effective complete solution for your business using enterprise-grade equipment."
                /> */}
          <SingleService
            icon={DatabaseBW}
            hoverIcon={Database}
            color="#E84855"
            alt="Database Icon"
            heading="Databases"
            paragraph="The core of an application is its data. Let us provide a design that will support your application and provide the integrity you need from your data.  We can work with your current system and plan out improvements, migrations, backups, and a full redesign if needed.  Let's make your data work for you now and into the future."
          />
          <SingleService
            icon={EmailBW}
            hoverIcon={Email}
            color="gray"
            alt="Email icon"
            heading="Corporate Email"
            paragraph="Would you like to send emails from your business?  A corporate email will ensure you look consistent and professional in your business communication. Communicating from your business domain gives your customers a sense of security and assurance. We can use your existing domain or provide you with a new business domain. No more sending emails as jane.doe@yahoo.com!"
          />
          {/* <SingleService
                    icon={WifiBW}
                    hoverIcon={WifiIcon}
                    color="#7AA095"
                    alt="Wifi Icon"
                    heading="Wireless Access"
                    paragraph="It's important to separate your corporate wireless traffic from your provided public wireless traffic.  We can provide you with enterprise equipment that will allow you to support hundreds of clients with the option to scale up if needed.  We could even configure a terms/conditions acceptance landing page as well as a &quot;pay for use&quot; portal option.  Rest easy knowing that your public wireless traffic will be completely separate from your business network."
                /> */}
        </div>
      </div>
    </Container>
  </Section>
);

export default Services;
