import React from 'react';
import swal from 'sweetalert';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components/macro';
import FormInput from './FormInput';
import Button from './Button';
import FormGroup from './FormGroup';

const StyledButton = styled(Button)`
   
    width: 120px;
    height: 50px;

`;
const Error = styled.span`
    color: red;
    margin-top: 10px;
    display: block;
`;


const Label = styled.label`
  line-height:2rem;
`;

const Form = styled.form`
display:block;
margin-top:0em;
`;


class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      namereg: /[A-Za-z]{1}[A-Za-z]/,
      emailreg: /^([\w-.]+@([\w-]+\.)+[\w-]{2,6})?$/,
      phonereg: /^(?:\([2-9]\d{2}\) ?|[2-9]\d{2}(?:-?| ?))[2-9]\d{2}[- ]?\d{4}$/,
      sanitizeReg: /[&<>"'/]/ig,
      name: null,
      phone: null,
      email: null,
      desc: null,
      captcha: null,
      errors: {
        name: '',
        phone: '',
        email: '',
        desc: '',
        captcha: ''
      }
    };

  }

  //check if valid on form submit onsubmit
  validateForm = (errors, data) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    Object.keys(data).forEach(
      // if we have an empty string set valid to false and set error message
      (val) => data[val] === null && (valid = false, errors[val] = "Cannot be empty")
    );
    return valid;
  }

  //sanitize description input
  sanitize = (string) => {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      "/": '&#x2F;',
    };

    //check if passed string is null if not sanitize
    if (string !== null)
      return string.replace(this.state.sanitizeReg, (match) => (map[match]));
    else
      return null;
  }

  handleCaptcha = (value) => {
    // if the captcha was successfull
    if (value.length > 0) {
      let errors = this.state.errors;
      errors.captcha = '';
      // set the return captcha value to the state
      this.setState({ errors, captcha: value })
    }

  }
  //on keypress check input
  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name':
        errors.name =
          !this.state.namereg.test(value)
            ? 'Please enter a valid name!'
            : '';
        break;
      case 'phone':
        errors.phone =
          this.state.phonereg.test(value)
            ? ''
            : 'Phone number is not valid!';
        break;
      case 'email':
        errors.email =
          this.state.emailreg.test(value) && value !== ""
            ? ''
            : 'Email is not valid!';
        break;
      case 'desc':
        errors.desc =
          value.length < 2
            ? 'Message must be longer!'
            : '';
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {
      console.log(errors)
    })
  }

  //on form submit check if all inputs are valid
  handleSubmit = (event) => {
    event.preventDefault();
    // set data object
    let data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      desc: this.sanitize(this.state.desc),
      captcha: this.state.captcha
    };

    if (this.validateForm(this.state.errors, data)) {
      //show sending message modal
      swal("Sending Message...", {
        buttons: false,
      });
      console.info('Valid Form')
      //send message
      fetch(this.props.URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(() => {
          // clear form and show a success message
          // alert("Successfull");
          swal("Email Submission", "Successful", "success").then(() => {
            document.getElementById("contact-form").reset();
            window.location.reload();
          });
        })
        .catch(() => {
          swal("Email Submission", "Transmission Error", "error");
        });

    } else {
      console.error('Invalid Form')
      this.handleChange(event);
      swal("Invalid Information", "", "error");
    }
  }
  render() {

    const { errors } = this.state;
    return (
      <Form onSubmit={this.handleSubmit} noValidate id="contact-form" method="post">
        <FormGroup>
          <Label htmlFor="name-input" >Name</Label>
          <FormInput type="text" id="name-input" name="name" placeholder="Enter name here…" onChange={this.handleChange} noValidate />
          {errors.name.length > 0 && <Error>{errors.name}</Error>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone-input" >Phone</Label>
          <FormInput type="phone" id="phone-input" name="phone" placeholder="Enter phone number here…" onChange={this.handleChange} noValidate />
          {errors.phone.length > 0 && <Error>{errors.phone}</Error>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email-input" >Email</Label>
          <FormInput type="email" id="email-input" name="email" placeholder="Enter email here…" onChange={this.handleChange} noValidate />
          {errors.email.length > 0 && <Error>{errors.email}</Error>}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="description-input" >How can we help you?</Label>
          <FormInput type="textarea" as="textarea" id="description-input" rows="5" name="desc" placeholder="Enter your message…" onChange={this.handleChange} noValidate />
          {errors.desc.length > 0 && <Error>{errors.desc}</Error>}
        </FormGroup>
        <FormGroup>
          <ReCAPTCHA
            sitekey="6LdJ_eQZAAAAAPMCPDvUOofAk3hc6cQ3ABbWoNAp"
            name="captcha"
            onChange={this.handleCaptcha}
          />
          {errors.captcha.length > 0 && <Error>{errors.captcha}</Error>}
        </FormGroup>
        <FormGroup>
          <StyledButton type="submit">Submit</StyledButton>
        </FormGroup>
      </Form>
    )
  }
}

export default EmailForm